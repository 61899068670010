import Vue from "vue";

// axios
import axios from "axios";

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: "https://super-royal.dev-station.com/api/website",
  headers: {
    "Content-type": "application/json",
  },
});

Vue.prototype.$http = axiosIns;

export default axiosIns;
