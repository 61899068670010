var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.News && _vm.News.length > 0)?_c('div',{staticClass:"news"},[_c('div',{staticClass:"news-content"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-8 first_col"},[_c('div',{staticClass:"big-card card",staticStyle:{"width":"100%","height":"100%"}},[_c('img',{staticClass:"img-fluid",attrs:{"src":'https://super-royal.dev-station.com/storage/contents/' + _vm.News[0].image,"alt":_vm.News[0].title}}),_c('div',{staticClass:"card-body text-start"},[_c('span',{staticClass:"badge bg-secondary"},[_vm._v(_vm._s(_vm.getDate(_vm.News[0].created_at)))]),_c('h5',{staticClass:"card-title"},[_vm._v(_vm._s(_vm.News[0].title))]),(!_vm.isMobile)?_c('p',{staticClass:"card-text",domProps:{"innerHTML":_vm._s(
                    _vm.News[0].description
                      ? _vm.News[0].description.slice(0, 200) + ' ...'
                      : ''
                  )}}):_c('p',{staticClass:"card-text",domProps:{"innerHTML":_vm._s(
                    _vm.News[0].description
                      ? _vm.News[0].description.slice(0, 200) + ' ...'
                      : ''
                  )}}),_c('span',{staticClass:"read_more"},[_c('a',[_c('router-link',{attrs:{"to":("/news/" + (_vm.News[0].id))}},[_vm._v("Read More")])],1),_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"16.829","height":"11.947","viewBox":"0 0 16.829 11.947"}},[_c('g',{attrs:{"id":"Group_1438","data-name":"Group 1438","transform":"translate(1.151 0.848)"}},[_c('line',{attrs:{"id":"Line_3","data-name":"Line 3","y1":"0.372","x2":"15","transform":"translate(-0.383 5.122)","fill":"none","stroke":"#013299","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"1.5"}}),_c('line',{attrs:{"id":"Line_4","data-name":"Line 4","y1":"5","x2":"5","transform":"translate(9.617 5.039)","fill":"none","stroke":"#013299","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"1.5"}}),_c('line',{attrs:{"id":"Line_5","data-name":"Line 5","x2":"5","y2":"5","transform":"translate(9.617 0.212)","fill":"none","stroke":"#013299","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"1.5"}})])])])])])]),(_vm.News.length > 1)?_c('div',{staticClass:"col-md-4 second-col"},[(_vm.News.length > 1)?_c('div',{staticClass:"small-card card",staticStyle:{"width":"100%"}},[_c('img',{staticClass:"img-fluid",attrs:{"src":'https://super-royal.dev-station.com/storage/contents/' + _vm.News[1].image,"alt":_vm.News[1].title}}),_c('div',{staticClass:"card-body text-start"},[_c('span',{staticClass:"badge bg-secondary"},[_vm._v(_vm._s(_vm.getDate(_vm.News[1].created_at)))]),_c('h5',{staticClass:"card-title"},[_vm._v(_vm._s(_vm.News[1].title))]),_c('p',{staticClass:"card-text",domProps:{"innerHTML":_vm._s(
                    _vm.News[1].description ? _vm.News[1].description.slice(0, 50) + ' ...' : ''
                  )}}),_c('span',{staticClass:"read_more"},[_c('a',[_c('router-link',{attrs:{"to":("/news/" + (_vm.News[1].id))}},[_vm._v("Read More")])],1),_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"16.829","height":"11.947","viewBox":"0 0 16.829 11.947"}},[_c('g',{attrs:{"id":"Group_1438","data-name":"Group 1438","transform":"translate(1.151 0.848)"}},[_c('line',{attrs:{"id":"Line_3","data-name":"Line 3","y1":"0.372","x2":"15","transform":"translate(-0.383 5.122)","fill":"none","stroke":"#013299","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"1.5"}}),_c('line',{attrs:{"id":"Line_4","data-name":"Line 4","y1":"5","x2":"5","transform":"translate(9.617 5.039)","fill":"none","stroke":"#013299","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"1.5"}}),_c('line',{attrs:{"id":"Line_5","data-name":"Line 5","x2":"5","y2":"5","transform":"translate(9.617 0.212)","fill":"none","stroke":"#013299","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"1.5"}})])])])])]):_vm._e(),(_vm.News.length > 2)?_c('div',{staticClass:"small-card card",staticStyle:{"width":"100%"}},[_c('img',{staticClass:"card-img-top img-fluid",attrs:{"src":'https://super-royal.dev-station.com/storage/contents/' + _vm.News[2].image,"alt":_vm.News[2].title}}),_c('div',{staticClass:"card-body text-start"},[_c('h3',[_c('span',{staticClass:"badge bg-secondary"},[_vm._v(" "+_vm._s(_vm.getDate(_vm.News[2].created_at)))])]),_c('h5',{staticClass:"card-title"},[_vm._v(_vm._s(_vm.News[2].title))]),_c('p',{staticClass:"card-text",domProps:{"innerHTML":_vm._s(
                    _vm.News[2].description ? _vm.News[2].description.slice(0, 50) + ' ...' : ''
                  )}}),_c('span',{staticClass:"read_more"},[_c('a',[_c('router-link',{attrs:{"to":("/news/" + (_vm.News[2].id))}},[_vm._v("Read More")])],1),_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"16.829","height":"11.947","viewBox":"0 0 16.829 11.947"}},[_c('g',{attrs:{"id":"Group_1438","data-name":"Group 1438","transform":"translate(1.151 0.848)"}},[_c('line',{attrs:{"id":"Line_3","data-name":"Line 3","y1":"0.372","x2":"15","transform":"translate(-0.383 5.122)","fill":"none","stroke":"#013299","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"1.5"}}),_c('line',{attrs:{"id":"Line_4","data-name":"Line 4","y1":"5","x2":"5","transform":"translate(9.617 5.039)","fill":"none","stroke":"#013299","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"1.5"}}),_c('line',{attrs:{"id":"Line_5","data-name":"Line 5","x2":"5","y2":"5","transform":"translate(9.617 0.212)","fill":"none","stroke":"#013299","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"1.5"}})])])])])]):_vm._e()]):_vm._e()]),(_vm.News.length > 3)?_c('div',{staticClass:"row second-row"},_vm._l((_vm.News.slice(3)),function(card){return _c('cards',{key:card.id,attrs:{"card":card}})}),1):_vm._e()])])]):_c('div',{staticClass:"no_data"},[_vm._m(0)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"innerdiv"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("../assets/images/nodata.svg"),"alt":"nodata"}}),_c('h2',[_c('span',[_vm._v("D")]),_vm._v("ata Not Found !")])])}]

export { render, staticRenderFns }