import { render, staticRenderFns } from "./carouselcompany.vue?vue&type=template&id=661e454e&scoped=true&"
import script from "./carouselcompany.vue?vue&type=script&lang=js&"
export * from "./carouselcompany.vue?vue&type=script&lang=js&"
import style0 from "./carouselcompany.vue?vue&type=style&index=0&id=661e454e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "661e454e",
  null
  
)

export default component.exports