<template>
  <div class="about">
    <div class="first-blocks">
      <div class="container">
        <div class="row align-items-center first-block">
          <div class="first-col col-sm-12 col-lg-6 mb-sm-5">
            <h2>About us</h2>
            <h1 v-if="AboutHero && AboutHero.title">{{ AboutHero.title }}</h1>
            <div class="scroll-pargrah">
              <p v-if="AboutHero && AboutHero.description">
                {{ AboutHero.description }}
              </p>
            </div>
          </div>
          <div class="second-col col-lg-6 col-sm-12">
            <img
              class="img-fluid"
              src="../assets/images/doctor_firstblock_about.png"
              alt="doctor"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- Section three  -->
    <div class="section-three">
      <h2>Our Mission & vision</h2>
      <div class="silde">
        <div class="container" v-if="AboutVision.length > 0">
          <carouselabout :vision-mission="AboutVision" />
        </div>
      </div>
      <div class="sub-section" v-if="AboutManage">
        <div class="row g-0">
          <div class="col-2 first"></div>
          <div class="col-sm-12 col-md-8 second">
            <div class="row">
              <div class="first-col col-sm-12 col-md-6 mb-sm-5 py-md-5">
                <h2>Managing Director Message</h2>
                <div class="scroll-pargrah">
                  <p>
                    {{ AboutManage.description }}
                  </p>
                </div>
              </div>
              <div class="second-col col-md-6 col-sm-12">
                <!-- <img
                  class="img-fluid"
                  src="../assets/images/secondsection_about.png"
                  alt="doctor"
                /> -->
                <!-- <img
                  class="img-fluid"
                  :src="'https://super-royal.dev-station.com/storage/contents/' + AboutManage.image"
                  alt="doctor"
                /> -->
                <div class="shape"></div>
                <div class="img_con">
                  <img
                    class="img-fluid"
                    :src="'https://super-royal.dev-station.com/storage/contents/' + AboutManage.image"
                    alt="doctor"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-2"></div>
        </div>
      </div>
    </div>

    <!-- Last Section -->
    <div class="last-section" v-if="AboutPrinciples && AboutPrinciples.length > 0">
      <div class="container">
        <h2>Our Principles And Values</h2>
        <div class="sub-last-section">
          <div class="row align-items-center justify-content-between g-0">
            <div class="col-lg-6">
              <img
                src="../assets/images/ourprinciple_about.png"
                class="img-fluid"
                alt="principles"
              />
            </div>
            <div class="col-lg-5">
              <div class="principles-content">
                <ul class="tabs-choose">
                  <li
                    v-for="(item, index) in AboutPrinciples"
                    :key="index"
                    @click="activeTab = index + 1"
                    :class="[activeTab == index + 1 ? 'active' : '']"
                  >
                    {{ item.title }}
                  </li>
                </ul>
                <div class="tabs-content">
                  <div
                    class="content-one"
                    v-for="(item, index) in AboutPrinciples"
                    :key="index"
                    v-if="activeTab == index + 1"
                  >
                    <h4>{{ item.title }}</h4>
                    <p>
                      {{ item.description }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import carouselabout from "@/components/about/carouselabout.vue";
import image from "../assets/images/medium-shot-nurse-doctor-checking-patient.svg";
export default {
  name: "aboutview",
  components: {
    carouselabout,
  },
  data() {
    return {
      activeTab: "1",
      image: image,
    };
  },
  computed: {
    ...mapGetters([
      "AboutHero",
      "AboutVision",
      "AboutManage",
      "AboutPrinciples",
      "HomePartnerImages",
    ]),
  },
  mounted() {
    this.getAbout();
  },
  methods: {
    ...mapActions(["getAbout"]),
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/pages/_about.scss";
</style>
